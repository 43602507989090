<template>
  <nav class="df ait w100-m">
    <accordion class="df menu" v-if="propData">
      <template v-for="(item, idx) in propData.items">
        <accordion-item
          v-if="item.children.length"
          :class="{ hasSubmenu: item.children.length }"
          :key="idx"
        >
          <template slot="accordion-trigger" id="c1">
            <span class="appLink"
              >{{ item.name
              }}<i class="icon ic-dropdown" v-if="item.children.length"></i
            ></span>
          </template>
          <template
            slot="accordion-content"
            v-if="item.children.length"
            class="subMenu"
          >
            <li class="submenuItem">
              <app-link :link="item"></app-link>
            </li>
            <li class="submenuItem" v-for="(childItem, idx) in item.children" :key="idx">
              <app-link :link="childItem"></app-link>
            </li>
          </template>
        </accordion-item>
        <li v-else :key="idx" class="noSubmenu">
          <app-link :link="item"></app-link>
        </li>
      </template>
    </accordion>
    <a
      v-if="globalSetting.phones"
      :href="`tel:+${globalSetting.phones[0].number}`"
      class="telephone"
      >{{ globalSetting.phones[0].label }}</a>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import AccordionItem from "../../common/Accordion-item.vue";
import Accordion from "../../common/Accordion.vue";
import appLink from "../app-link.vue";
export default {
  components: { appLink, Accordion, AccordionItem },
  props: {
    propData: {
      type: Object
    }
  },
  computed: {
      ...mapGetters(["globalSetting"])
  },
};
</script>
<style lang="sass">
.appLink
    display: flex
    justify-content: space-between
    width: 100%
.menu .accordion__trigger
    border-bottom: 1px solid #2A4651
    padding-bottom: 10px
.menu .accordion__content
    margin-top: 27px
    li
        border-bottom: 1px solid #2A4651
    li:last-of-type
        // border-bottom: none
        // margin-bottom: 20px
.menu .accordion__trigger_active
    border-bottom: none !important
    color: $c-btn
    i::before
        color: inherit !important
.menu .router-link-active
    color: $c-btn
.accordion__content .submenuItem
    font-weight: 300 !important
.accordion__content
    margin-bottom: 40px !important
</style>
<style lang="sass" scoped>
.w100-m
    @media (max-width: 576px)
        width: 100%
        display: flex
        flex-direction: column
        .telephone
            margin-top: 50px
.menu
    position: relative
    height: 100%
    @media (max-width: 576px)
        display: flex
        flex-direction: column
        width: 100%
        overflow-y: scroll
        max-height: 70vh
        li
            margin-right: 0px !important
            padding-bottom: 10px
            &:not(:first-of-type)
                margin-top: 27px
            a
                justify-content: space-between !important
                width: 100%
    li
        font-style: normal
        font-weight: 500
        font-size: 15px
        line-height: 100%
        color: #FFFFFF
        &:not(:last-of-type)
            margin-right: 40px
        a
            transition: .3s
            height: 100%
            display: flex
            align-items: center
            justify-content: center
        &:hover
            .appLink
                color: $c-btn
.noSubmenu
    border-bottom: 1px solid #2A4651
.hasSubmenu
    position: relative
    &:before
        content: ""
        bottom: -12px
        left: 50%
        width: 10px
        height: 10px
        transform: translate(-50%, 0) rotate(45deg)
        background-color: $c-gray
        position: absolute
        z-index: 5
        opacity: 0
        transition: .3s
        // transition-delay: 0.2s
    .ic-dropdown
        vertical-align: bottom
    &:hover
        &:before
            transition-delay: 0s
            opacity: 1
            max-height: 1000px
        .subMenu
            transition-delay: 0s
            opacity: 1
            visibility: visible
.subMenu
    opacity: 0
    margin-top: 27px
    visibility: hidden
    transition: .3s
    // transition-delay: 0.3s
    max-height: 0px
    visibility: hidden
    background-color: #ccc
    padding: 15px
    // max-height: 235px
    top: calc(100% + 5px)
    left: 0px
    background-color: $c-gray
    display: grid
    grid-template-columns: 1fr
    width: 100%
    &::before
        content: ""
        width: 100%
        top: -10px
        height: 10px
        left: 0
        position: absolute
    li
        font-weight: 300
        font-size: 14px
        line-height: 100%
        padding-bottom: 10px
        border-bottom: 1px solid #2A4651
        margin-bottom: 10px
        margin-right: 40px
        text-align: left
        *
            text-align: left
        a
            display: block
        &:hover
            a
                color: $c-btn
</style>
