<template>
    <section class="calendar filterForm container">
        <h2 class="sectionTitle bef">Пошук турів та авіабілетів</h2>
        <p class="smallText">
            Пошук турів від DreamTravel запропонує для вас кращі пропозиції з
            більше ніж 70 різних туроператорів
        </p>
        <ul class="type">
            <li class="active">Турів</li>
            <li>Готелей</li>
        </ul>
        <form class="ticketForm">
            <div class="inputWrapper">
                <input type="text" placeholder="Куди плануєте подорож" />
            </div>
            <div class="inputWrapper">
                <label>Вильот із</label>
                <input type="text" placeholder="Київ" />
            </div>
            <div class="inputWrapper">
                <label>Тривалість</label>
                <input type="text" placeholder="7-9 ночей" />
            </div>
            <div class="inputWrapper">
                <label>Дата польоту</label>
                <input type="text" placeholder="5 жовт, вт - 11 жовт, пн" />
            </div>
            <div class="inputWrapper">
                <label>Туристи</label>
                <input type="text" placeholder="2 дорослих" />
            </div>
            <input type="submit" class="submit" value="Підібрати білет" />
        </form>
    </section>
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
.calendar
    display: flex
    flex-direction: column
    align-items: center
    // @include sectionPadding
    // @media (max-width: 830px)
    //     padding: 100px 0px
    .filter
        display: flex
        color: #fff
        li
            cursor: pointer
            padding: 8px 16px
            &:not(:last-of-type)
                border-right: 1px solid #28292F
.filterTour
    display: grid
    width: 100%
    grid-template-columns: repeat( 4, 1fr)
    grid-gap: 8px
    @media (max-width: 1024px)
      grid-template-columns: repeat(3, 1fr)
    li
        width: 100%
.filterTitle
    font-size: 12px
    line-height: 100%
    color: $t-gray
.type
    display: flex
    border: 2px solid #4AA7BC
    box-sizing: border-box
    margin-top: 70px
    margin-bottom: 30px
    li
        padding: 10px 14px
        font-weight: 300
        font-size: 14px
        line-height: 100%
        color: #4AA7BC
        &.active
            color: #17171D
            font-weight: 600
            background-color: #4AA7BC
// ticketForm
.ticketForm
    background-color: #fff
    display: flex
    width: 100%
    input
        border: none
        outline: none
    .inputWrapper
        height: 70px
        display: flex
        flex-direction: column
        background-color: #fff
        width: auto
        display: flex
        padding: 0px 24px
        justify-content: center
        position: relative
        &:not(:last-of-type)
            &::before
                content: ''
                position: absolute
                right: 0px
                background-color: #CACACA
                width: 1px
                height: 55px
                top: 50%
                transform: translateY(-50%)
        label
            font-size: 12px
            line-height: 100%
            color: #28292F
            font-weight: 600
        &:nth-child(1)
            flex: 2
        &:nth-child(2)
            max-width: 107px
        &:nth-child(3)
            max-width: 131px
        &:nth-child(4)
            max-width: 233px
        &:nth-child(5)
            max-width: 217px
    .submit
        padding: 0px 36px
        background-color: #4AA7BC
        color: #fff
        font-weight: 600
        font-size: 18px
        line-height: 120%
    @media (max-width: 1024px)
        flex-wrap: wrap
        background-color: transparent
        justify-content: center
        .submit
            margin-top: 30px
            padding: 24px 35px
    @media (max-width: 830px)
        .inputWrapper
            width: 100%
            max-width: unset !important
            margin-bottom: 2px
.smallText
    max-width: 586px
.sectionTitle
    margin-bottom: 32px
</style>
