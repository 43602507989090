<template>
    <section class="imgBlock">
      <img :src="require('@/assets/image/gall.png')" alt="" />
    </section>
</template>

<script>
    export default {

    }
</script>

<style lang="sass" scoped>
.imgBlock
    img
        width: 100%
</style>
