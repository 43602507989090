<template>
  <ul class="lang-switcher df">
    <li :class="{ active: $i18n.locale == 'en' }">
      <router-link
        :to="enLang"
        v-if="$i18n.locale != 'en'"
        @click.native="onLangChange"
        >Eng</router-link
      >
      <span v-else>Eng</span>
    </li>
    <span></span>
    <li :class="{ active: $i18n.locale == 'uk' }">
      <router-link
        :to="ukLang"
        v-if="$i18n.locale != 'uk'"
        @click.native="onLangChange"
        >Укр</router-link
      >
      <span v-else>Укр</span>
    </li>
  </ul>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { rtParams } from "@/utils.js";
export default {
  data() {
    return {
      currentlocale: this.$i18n.locale
    };
  },
  computed: {
    ...rtParams,
    enLang() {
      return {
        name: this.$route.name,
        params: this.enLocaleParam,
        query: this.$route.query
      };
    },
    ukLang() {
      return {
        name: this.$route.name,
        params: this.ukLocaleParam,
        query: this.$route.query
      };
    },
    ...mapGetters(["getSize", "isUiLocked", "headData", "globalSetting"])
  },
  methods: {
    ...mapActions([
      "lockUi",
      "unlockUi",
      "getHeaderData",
      "getFooterMenu",
      "footerFetch"
    ]),
    async onLangChange() {
      const res = await this.axios.post("/api/menu/get-by-ids", {
        ids: [1, 2],
        lang: this.currentLang
      });
      this.getHeaderData(res.data.data.items[1]);
      this.getFooterMenu(res.data.data.items[2]);
       this.footerFetch(this.currentLang);
    }
  }
};
</script>

<style lang="sass" scoped>
.lang-switcher
    margin-left: auto
    li
        font-size: 14px
        line-height: 100%
        color: $t-gray
        transition: .3s
        &:hover
            color: $c-btn
        &.active
            color: #fff
            font-weight: 600
        &:first-of-type
            margin-right: 20px
            position: relative
            &::after
                position: absolute
                content: ''
                width: 1px
                height: 20px
                background-color: #28292F
                top: -2px
                right: -10px
                @media (max-width: 576px)
                    background-color: #777
</style>
