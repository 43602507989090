import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../pages/Home.vue";

Vue.use(VueRouter);
const routes = [{
        path: "/:locale(uk|en)?/",
        name: "Home",
        component: Home
    },
    {
        path: "/:locale(uk|en)?/faq",
        name: "Faq",
        component: () =>
            import(
                /* webpackChunkName: "Faq" */
                "../pages/Faq.vue"
            )
    },
    {
        path: "/:locale(uk|en)?/katalog",
        name: "Catalog",
        component: () =>
            import(
                /* webpackChunkName: "chooseCountry" */
                "../pages/chooseCountry.vue"
            ),
        children: [{
            path: ':slug/:month?',
            name: "CatalogChild",
            component: () =>
                import(
                    /* webpackChunkName: "chooseCountryChild" */
                    "../pages/chooseCountry.vue"
                ),
        }]
    },
    {
        path: "/:locale(uk|en)?/news",
        name: "Blog",
        component: () =>
            import(
                /* webpackChunkName: "Blog" */
                "../pages/Blog.vue"
            )
    },
    {
        path: "/:locale(uk|en)?/news/tag/:tag_name",
        name: "TagsPage",
        component: () =>
            import(
                /* webpackChunkName: "TagsPage" */
                "../pages/Tags.vue"
            )
    },
    {
        path: "/:locale(uk|en)?/news/:slug",
        name: "BlogCategory",
        component: () =>
            import(
                /* webpackChunkName: "Blog" */
                "../pages/Blog.vue"
            )
    },
    {
        path: "/:locale(uk|en)?/news/:category/:slug",
        name: "Article",
        component: () =>
            import(
                /* webpackChunkName: "Blog" */
                "../pages/ArticlePage.vue"
            )
    },
    {
        path: "/:locale(uk|en)?/:textPageSlug",
        name: "TextPage",
        component: () =>
            import(
                /* webpackChunkName: "textPage" */
                "../pages/TextPage.vue"
            )
    },
    {
        path: "/:locale(uk|en)?/country/:countryName",
        name: "Contry",
        component: () =>
            import(
                /* webpackChunkName: "countryInfo" */
                "../pages/Country.vue"
            )
    },
    {
        path: "/:locale(uk|en)?/country/:countryName/hotels",
        name: "ContryHotels",
        component: () =>
            import(
                /* webpackChunkName: "countryHotels" */
                "../pages/CountryHotels.vue"
            )
    },
    {
        path: "/:locale(uk|en)?/country/:countryName/hotels/:slug",
        name: "HotelInfo",
        component: () =>
            import(
                /* webpackChunkName: "HotelInfo" */
                "../pages/HotelInfo.vue"
            )
    },
    {
        path: "/:locale(uk|en)?/country/:countryName/tours",
        name: "CountryTours",
        component: () =>
            import(
                /* webpackChunkName: "CountryTour" */
                "../pages/CountryTours.vue"
            )
    },
    {
        path: "/:locale(uk|en)?/country/:countryName/:infoPageSlug",
        name: "CountryInfos",
        component: () =>
            import(
                /* webpackChunkName: "CountryInfos" */
                "../pages/CountryInfo.vue"
            )
    },
    {
        path: "/:locale(uk|en)?/country/:countryName/tours/:slug",
        name: "TourInfo",
        component: () =>
            import(
                /* webpackChunkName: "TourInfo" */
                "../pages/TourInfo.vue"
            )
    },
    {
        path: "/:locale(uk|en)?/404",
        name: "NotFound",
        component: () =>
            import(
                /* webpackChunkName: "NotFound" */
                "../pages/NotFound.vue"
            )
    },
    {
        path: "*",
        redirect: "/404"
    }
];

const router = new VueRouter({
    mode: "history",
    scrollBehavior(to, from, savedPosition) {
        if (from.name != to.name) {
            if (savedPosition) return savedPosition;
            return {
                x: 0,
                y: 0
            };
        } else {
            let top = document.querySelector(".tabs");
            if (top) {
                return {
                    x: 0,
                    y: top.offsetTop
                };
            }
        }
    },
    routes
});

export default router;
