import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import * as VueGoogleMaps from "vue2-google-maps";
import Vuelidate from "vuelidate";

// component
import appLink from "@/components/navigation/app-link.vue";
import defaultLink from "@/components/navigation/default-link.vue";

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

Vue.component('app-link', appLink)
Vue.component('default-link', defaultLink)

Vue.use(VueGoogleMaps, {
  load: {
    key: localStorage.getItem("google_map_key"),
    libraries: "places"
  }
});

import "./filters/filter.js";

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(Vuelidate);

import { utils } from "@/utils.js";

import i18n from "./i18n";

Vue.mixin(utils);

router.beforeEach((to, from, next) => {
  // alert(to.name)
  i18n.locale = to.params.locale || "en";
  next();
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount("#app");
