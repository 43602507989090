<template>
  <section class="container seoContainer">
      <div
        ref="contentZone"
        class="contentWrapper seoText"
        v-html="propsData"
      ></div>
    </section>
</template>

<script>
import accordionMixin from "@/textAccordion";
export default {
  mixins: [accordionMixin],
  props: {
      propsData: {
          type: String,
      },
  },
  mounted() {
    setTimeout(() => {
      this.initToggleSlideBtn(this.$refs.contentZone, false); // from mixin
    }, 500);
  }
};
</script>

<style lang="scss" scoped>
</style>
