<template>
    <component :is="template" :propData="propsData" ></component>
</template>

<script>
import appChooseUs from "../app-chooseUs.vue";
import AppShowCase from '../app-showCase.vue';
import AppTours from '../app-tours.vue';
export default {
  components: { appChooseUs, AppTours, AppShowCase },
  props: {
    propsData: {
      type: [Object, Array],
    },
  },
  computed: {
      template() {
          if ( this.propsData.template === 'grid' ) {
            return 'app-tours'
          } else if ( this.propsData.template === 'custom' ) {
              return 'appChooseUs'
          } else {
              return 'app-show-case'
          }
      }
  },
};
</script>

<style lang="sass" scoped>
</style>
