<template>
  <nav class="df ait" v-if="!isUiLocked">
    <ul class="df menu" v-if="propData">
      <!-- <li
        v-for="(item, idx) in propData.items"
        :class="{ hasSubmenu: item.children.length }"
        :key="idx"
      >
        <app-link class="appLink" :link="item"
          ><i class="icon ic-dropdown" v-if="item.children.length"></i
        ></app-link>
        <ul v-if="item.children.length" class="subMenu">
          <li v-for="(childItem, idx) in item.children" :key="idx">
            <app-link :link="childItem"></app-link>
          </li>
        </ul>
      </li>-->
      <li
        v-for="(item, idx) in propData.items"
        :class="{ hasSubmenu: item.children.length }"
        :key="idx"
        @click="getCurrentIdx(idx)"
      >
        <span v-if="item.children.length">
            {{ item.name }}
            <i class="icon ic-dropdown" v-if="item.children.length"></i>
        </span>
        <app-link class="appLink" :link="item" v-else
          ><i class="icon ic-dropdown" v-if="item.children.length"></i
        ></app-link>
      </li>
    </ul>
    <ul class="submenu" v-if="submenuList && submenuList.length">
    <li>
        <app-link :link="propData.items[currentIdx]"></app-link>
    </li>
      <li v-for="(item, idx) in submenuList" :key="idx">
            <app-link :link="item"></app-link>
      </li>
    </ul>
  </nav>
</template>
<script>
import { mapGetters } from 'vuex';
// import appLink from "../app-link.vue";
export default {
  //   components: { appLink },
  props: {
    propData: {
      type: Object
    }
  },
  data() {
    return {
      submenuList: null,
      currentIdx: null
    };
  },
  computed: {
    ...mapGetters(["isUiLocked"]),
  },
  methods: {
      getCurrentIdx(idx) {
          this.currentIdx = idx;
          this.submenuList = this.propData.items[idx].children
      }
  },

};
</script>

<style lang="sass" scoped>
.menu
    position: relative
    height: 100%
    li
        font-style: normal
        font-weight: 600
        font-size: 15px
        line-height: 100%
        color: #FFFFFF
        &:not(:last-of-type)
            margin-right: 40px
            @media (max-width: 1024px)
                margin-right: 48px
            @media (max-width: 830px)
                margin-right: 20px
        a
            transition: .3s
            height: 100%
            display: flex
            align-items: center
            justify-content: center
        &:hover
            .appLink
                color: $c-btn
.hasSubmenu
    position: relative
    @media (max-width: 1024px)
        position: static
    &:before
        content: ""
        bottom: -12px
        left: 50%
        width: 10px
        height: 10px
        transform: translate(-50%, 0) rotate(45deg)
        background-color: $c-gray
        position: absolute
        z-index: 5
        opacity: 0
        transition: .3s
        // transition-delay: 0.2s
    .ic-dropdown
        vertical-align: bottom
    &:hover
        &:before
            transition-delay: 0s
            opacity: 1
        .subMenu
            transition-delay: 0s
            opacity: 1
            visibility: visible
.subMenu
    opacity: 0
    visibility: hidden
    transition: .3s
    // transition-delay: 0.3s
    // position: absolute
    background-color: #ccc
    z-index: 3
    padding: 15px
    max-height: 235px
    top: calc(100% + 5px)
    left: 0px
    background-color: $c-gray
    display: grid
    grid-template-columns: repeat(3, 1fr)

// new style
nav
    display: flex
    flex-direction: column
.submenu
    width: 100%
    margin-top: 12px
    background-color: $c-black
    display: grid
    padding: 15px
    position: relative
    grid-template-columns: repeat(3, 1fr)
    column-gap: 30px
    &::before
        content: ""
        width: 100%
        top: -10px
        height: 10px
        left: 0
        position: absolute
    li
        font-weight: 300
        font-size: 14px
        line-height: 100%
        padding-bottom: 10px
        border-bottom: 1px solid #2A4651
        margin-bottom: 10px
        text-align: left
        *
            text-align: left
        a
            display: block
        &:hover
            a
                color: $c-btn
// new style end
</style>
