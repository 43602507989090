<template>
  <div class="home">
    <!-- :style="`background-image: url(${require('@/assets/image/first_bg.png')})`" -->
    <section
      v-if="!isUiLocked"
      class="firstScreen"
      style="
        background-image: url(https://ohmydream.club/images/first_bg.png);
      "
    >
      <div class="text">
        <span class="bef">{{
          this.homePageData.translate.description | removeTagP
        }}</span>
        <h1>{{ this.homePageData.translate.title }}</h1>
      </div>
    </section>
    <template v-if="homePageData">
      <component
        v-for="(item, idx) in homePageData.constructor"
        :is="item.component"
        :key="idx"
        :propsData="item.content"
      >
      </component>
      <component
        v-for="(item, idx) in homePageData.widgets"
        :is="item.component"
        :key="`${idx}w`"
        :propsData="item.content"
      >
      </component>
    </template>
    <app-seo-text v-if="seoText" :propsData="seoText"></app-seo-text>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AppListItem from "../components/common/app-listItem.vue";
import AppFilterForm from "../components/app-filterForm.vue";
import AppCalendar from "../components/app-calendar.vue";
import AppNews from "../components/app-news.vue";
import AppBlock from "../components/home/app-blocks.vue";
import AppSimpleText from "../components/text-components/app-simple-text.vue";
import AppFullSizeImg from "../components/common/app-fullSizeImg.vue";
import AppShowCase from "../components/app-showCase.vue";
import AppSeoText from '../components/common/app-seoText.vue';


export default {
  name: "Home",
  components: {
    "image-and-text": AppListItem,
    calendar: AppCalendar,
    AppFilterForm,
    news: AppNews,
    blocks: AppBlock,
    "simple-text": AppSimpleText,
    "full-width-image": AppFullSizeImg,
    selected: AppShowCase,
    AppSeoText
  },

  data() {
   return {
      homePageData: null
    };
  },
  methods: {
    ...mapActions(["lockUi", "unlockUi"])
  },
  computed: {
      seoText() {
          return this.homePageData ? this.homePageData.translate.seo_text : null
      },
    ...mapGetters(["isUiLocked", "globalSetting"])
  },
  async created() {
    this.lockUi();
    let test = null;
    if (
      this.$route.fullPath.split("/").splice(1, 3).join() === "" ||
      this.$route.fullPath.split("/").splice(1, 3).join() == "uk"
    ) {
      test = "/";
    } else {
      test = this.$route.fullPath.split("/").splice(1, 3).join();
    }
    const res = await this.axios.post("/api/page/get-by-slug", {
      lang: this.currentLang,
      slug: test
    });
    document.title =
      res.data.data.translate.meta_title ||
      res.data.data.translate.title ||
      "Dream Travel";
    this.homePageData = res.data.data;
    this.unlockUi();
  },
  mounted() {}
};
</script>

<style lang="sass">
.firstScreen
    height: calc(100vh - 130px)
    background-size: cover
    background-repeat: no-repeat
    position: relative
    display: flex
    justify-content: center
    align-items: flex-end
    text-align: center
    color: #fff
    z-index: 2
    &::before
        content: ''
        position: absolute
        top: 0px
        left: 0px
        right: 0px
        bottom: 0px
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%)
        z-index: -1
    .text
        h1
            font-weight: bold
            font-size: 91px
            line-height: 100%
            margin-top: 0px
            margin-bottom: 95px
            @media (max-width: 1024px)
                font-size: 52px
                margin-bottom: 60px
            @media (max-width: 830px)
                font-size: 35px
                margin-bottom: 30px
            @media (max-width: 576px)
                font-size: 35px
                line-height: 100%
                margin-top: 20px
        span
            font-weight: 500
            font-size: 25px
            line-height: 140%
            position: relative
            @media (max-width: 830px)
                font-size: 16px
                line-height: 140%
            &::after
                top: -7px !important
            &::before
                bottom: -7px
.bottomInfo
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center
    color: #fff
    @media (max-width: 830px)
        .descr
            max-width: 293px
    @media (max-width: 576px)
        flex-direction: column
        text-align: center
    .number
        font-size: 39px
        margin-bottom: 5px
        margin-top: 0px
</style>
