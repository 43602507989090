<template>
  <transition name="fade">
    <div class="cookie" v-if="!isPrivatPolicyAproove && !isAproove">
      <div class="cookie-content">
        <p class="title">{{ globalSetting.cookie_button_title }}</p>
        <p class="desc" v-html="globalSetting.cookie_text"></p>
        <div class="btns">
          <a @click="agree" class="btn">{{ globalSetting.cookie_button_title }}</a>
          <a @click="isAproove = !isAproove" class="close">&times;</a>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      isAproove: false
    };
  },
  computed: {
    isPrivatPolicyAproove() {
      if (this.$cookies.get("PrivatPolicy") == "aproove") {
        return true;
      } else {
        return false;
      }
    },
    ...mapGetters(["globalSetting"])
  },
  methods: {
    agree() {
      this.isAproove = true;
      this.$cookies.set("PrivatPolicy", "aproove");
    }
  },
};
</script>
<style lang="sass">
.cookie .desc > a
    color: $c-btn
    text-decoration: underline
</style>
<style lang="sass" scoped>
.cookie
    position: fixed
    left: 20px
    bottom: 20px
    background-color: $c-gray
    z-index: 1000
    padding: 30px 25px
    color: #fff
    max-width: 377px
    width: 100%
    @media (max-width: 576px)
        left: 0px
        bottom: 0px
    .title
        font-weight: 700
        font-size: 19px
        line-height: 110%
        margin-bottom: 30px
    .btn
        width: 100%
        text-align: center
        cursor: pointer
    .close
        position: absolute
        top: 13px
        right: 13px
        font-size: 36px
        line-height: 26px
        color: #777
        cursor: pointer
</style>
