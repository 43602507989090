var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.getSize === 'desktop' && !_vm.isUiLocked)?_c('header',{ref:"header"},[_c('div',{staticClass:"header__top container df jcsb"},[(_vm.globalSetting.phones)?_c('a',{staticClass:"telephone",attrs:{"href":`tel:+${_vm.globalSetting.phones[0].number}`}},[_vm._v(_vm._s(_vm.globalSetting.phones[0].label))]):_vm._e(),_c('div',{staticClass:"r-block df ait jcsb"},[_c('app-lang-switcher')],1)]),_c('div',{staticClass:"header__bot"},[_c('div',{staticClass:"container no-p df jcsb"},[_c(_vm.$route.name == 'Home' ? 'span' : 'router-link',{tag:"component",staticClass:"logo",attrs:{"to":{
          name: 'Home',
          params: { locale: _vm.$i18n.locale == 'en' ? null : _vm.$i18n.locale }
        }}},[_c('img',{staticClass:"logo",attrs:{"src":_vm.path(_vm.globalSetting.site_logo_header),"alt":"logo"}})]),_c('nav-menu',{attrs:{"propData":_vm.headData}})],1)])]):_c('header',[_c('div',{staticClass:"container header__bot df jcsb"},[_c(_vm.$route.name == 'Home' ? 'span' : 'router-link',{tag:"component",staticClass:"logo",attrs:{"to":{
        name: 'Home',
        params: { locale: _vm.$i18n.locale == 'en' ? null : _vm.$i18n.locale }
      }}},[_vm._v(_vm._s(_vm.globalSetting.logo_text))]),_c('button',{staticClass:"burgerTrigger",on:{"click":function($event){_vm.menuOpen = !_vm.menuOpen}}},[_c('span'),_c('span'),_c('span')])],1),_c('div',{staticClass:"mobMenu",class:{ open: _vm.menuOpen }},[_c('div',{staticClass:"top"},[(_vm.globalSetting.phones && _vm.getSize !== 'desktop')?_c('a',{staticClass:"telephone",attrs:{"href":`tel:+${_vm.globalSetting.phones[0].number}`}},[_vm._v(_vm._s(_vm.globalSetting.phones[0].label))]):_vm._e(),_c('div',{staticClass:"r-block df ait jcsb"},[_c('app-lang-switcher'),_c('button',{staticClass:"burgerTriggerClose",on:{"click":function($event){_vm.menuOpen = !_vm.menuOpen}}},[_vm._v(" × ")])],1)]),_c('div',{staticClass:"bot"},[_c(_vm.menuView,{tag:"component",attrs:{"propData":_vm.headData}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }