<template>
  <div class="checkGroup">
    <div
      class="item"
      v-for="(item, index) in value"
      :class="{ active: item }"
      :key="index"
      @click="change(index)"
    >
      <div class="deco"><i class="ic-check1"></i></div>
      {{ items[index] }}
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckBoxGroup",
  props: [
    "styleClass",
    "placeholder",
    // "errors",
    "items",
    "propname",
    "validate",
    "showError",
    "wrpstyle",
    // "mode",
    "prevalue",
    "title"
  ],
  data() {
    return {
      value: []
    };
  },
  methods: {
    updateItems() {
      const a = [];
      for (let index = 0; index < this.items.length; index++) {
        a.push(false);
      }
      this.value = a;
    },
    change(i) {
      this.value[i] = !this.value[i];
      let a = JSON.stringify(this.value);
      this.$set(this, "value", JSON.parse(a));
      let arg = [];
      this.value.forEach((item, i) => {
        if (item) arg.push(this.items[i]);
      });
      this.$emit("input", arg);
    }
  },
  mounted() {
    this.updateItems();
  }
};
</script>

<style lang="sass" scoped>
.checkGroup
  margin: 25px 0
  .item
    font-style: normal
    font-weight: 600
    font-size: 12px
    line-height: 100%
    color: #777777
    cursor: pointer
    .deco
      width: 16px
      height: 16px
      box-shadow: 0 0 0 1px #777777
      display: inline-block
      vertical-align: middle
      margin-right: 10px
      i
        height: 100%
        width: 100%
        text-align: center
        color: #fff
        font-size: 10px
        justify-content: center
        align-items: center
        display: none
    & + .item
      margin-top: 14px
    &.active
      color: $c-btn
      .deco
        background-color: $c-btn
        box-shadow: 0 0 0 1px $c-btn
        i
          display: flex
</style>
