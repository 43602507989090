export default({
    state: {
        lockingPool: 0
    },
    getters: {
        isUiLocked: state => state.lockingPool > 0
    },
    mutations: {
        LOCK_UI: state => state.lockingPool++,
        UNLOCK_UI: state => state.lockingPool--
    },
    actions: {
        lockUi( {commit} ) {
            commit('LOCK_UI')
        },
        unlockUi({commit}) {
            commit('UNLOCK_UI')
        }
    }
});
