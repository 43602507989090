<template>
  <router-link v-if="link" :to="cardUrl" class="newItem">
    <router-link :to="tagUrl" class="tag">{{ tagName }}</router-link>
    <p class="title">
      {{ data.name }}
    </p>
    <p class="descr" v-html="data.text"></p>
    <p class="date">{{ data.public_date }}</p>
  </router-link>
</template>

<script>
export default {
  name: "AppNewsItem",
  props: {
    data: {
      type: Object
    },
    link: {
      type: String
    }
  },
  computed: {
    cardUrl() {
      if (
        this.$route.name == "Blog" ||
        this.$route.name == "Article" ||
        this.$route.name == "BlogCategory" ||
        this.$route.name == "TagsPage"
      ) {
        return this.link;
      } else {
        return "/" + this.link;
      }
    },
    tagUrl() {
      if (
        this.$route.name == "Blog" ||
        this.$route.name == "Article" ||
        this.$route.name == "BlogCategory" ||
        this.$route.name == "TagsPage"
      ) {
        return this.data.category
          ? this.data.category.url
          : this.data.category_url;
      } else {
        return this.data.category
          ? "/" + this.data.category.url
          : "/" + this.data.category_url;
      }
    },
    tagName() {
      return this.data.category
        ? this.data.category.name
        : this.data.category_title;
    }
  }
};
</script>

<style lang="sass" scoped>
.newItem
    padding: 20px
    background-color: #28292F
    transition: .3s
    &:hover
        box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2)
    .tag
        font-weight: 600
        font-size: 12px
        display: block
        line-height: 130%
        color: #4AA7BC
        position: relative
        margin-bottom: 20px
        transition: .3s
        &:hover
            color: #82CCDC
        &::before
            content: '|'
            color: #4AA7BC
            margin-right: 10px
    .title
        font-size: 16px
        line-height: 130%
        color: #FFFFFF
        font-weight: 600
        margin-bottom: 10px
    .descr
        font-weight: 300
        font-size: 17px
        line-height: 150%
        color: #CACACA
        min-height: 105px
        margin-bottom: 25px
        max-height: 105px
        overflow-y: hidden
    .date
        font-size: 12px
        line-height: 100%
        color: #CACACA
</style>
