export default {
    data() {
        return {
            accordMode: false,
            accordShow: false,
        };
    },
    watch: {
        accordShow(newValue) {
            switch (newValue) {
                case true:
                    if (this.accordMode)
                        this.slideDown(
                            document.querySelector(".toggleBtn.close")
                        );
                    this.slideUp(document.querySelector(".toggleBtn.open"));
                    break;
                case false:
                    this.slideUp(document.querySelector(".toggleBtn.close"));
                    this.slideDown(document.querySelector(".toggleBtn.open"));
                    break;
            }
        },
    },
    methods: {
        onAnkerClick(e){
          if(!this.accordShow){
            let el = document.getElementById(e);
            if(el.closest('.hideDiv')){
              this.accordToggleText();
            }
          }
        },
        accordToggleText() {
            let items = this.$refs.contentZone.querySelectorAll(".hideDiv");
            if (this.accordShow === false) {
                items.forEach((item) => {
                    this.slideDown(item);
                });
                this.accordShow = !this.accordShow;
            } else if (this.accordShow === true) {
                items.forEach((item) => {
                    this.slideUp(item);
                });
                this.accordShow = !this.accordShow;
            }
        },
        nextAll(element) {
            const nextElements = [];
            let nextElement = element;

            while (nextElement.nextElementSibling) {
                nextElements.push(nextElement.nextElementSibling);
                nextElement = nextElement.nextElementSibling;
            }

            return nextElements;
        },
        insertSlideUpBtn(el) {
            let secondToggleBtn = document.createElement("button");
            secondToggleBtn.classList = "toggleBtn close";
            secondToggleBtn.innerHTML =
                'toggleBtn <i class="ic-arrow-down"></i>';
            secondToggleBtn.style.display = "none";
            secondToggleBtn.addEventListener("click", this.accordToggleText);
            el.appendChild(secondToggleBtn);
        },
        initToggleSlideBtn(mainTextContainer, accordionMode = false) {
            this.accordMode = accordionMode;
            let marker = mainTextContainer.querySelector(".hideMarker");
            if (marker) {
                let perrentParag = marker.closest("p");
                let elems = this.nextAll(perrentParag);
                let fragment = document.createDocumentFragment();
                for (let index = 0; index < elems.length; index++) {
                    fragment.appendChild(elems[index]);
                }
                let hideDiv = document.createElement("div");
                hideDiv.classList = "hideDiv";
                hideDiv.style.display = "none";
                hideDiv.appendChild(fragment);
                let textBlockNode = perrentParag.parentElement;
                let toggleBtn = document.createElement("button");
                toggleBtn.classList = "toggleBtn open";
                toggleBtn.innerHTML = `${this.$t(
                    "readMore"
                )}<i class="ic-arrow-down"></i>`;
                toggleBtn.addEventListener("click", this.accordToggleText);
                textBlockNode.appendChild(toggleBtn);
                textBlockNode.appendChild(hideDiv);
                let condition = perrentParag.parentElement.nextElementSibling;
                if (
                    !condition &&
                    this.accordMode &&
                    !perrentParag.parentElement.parentElement.nextElementSibling
                ) {
                    this.insertSlideUpBtn(perrentParag.parentElement);
                }
                while (condition) {
                    condition.classList.toggle("hideDiv", true);
                    condition.style.display = "none";
                    if (!condition.nextElementSibling) {
                        if (this.accordMode === true) {
                            this.insertSlideUpBtn(condition)
                        }
                    }
                    condition = condition.nextElementSibling;
                }
                condition =
                    perrentParag.parentElement.parentElement.nextElementSibling;
                while (condition) {
                    condition.classList.toggle("hideDiv", true);
                    condition.style.display = "none";
                    if (!condition.nextElementSibling) {
                        if (this.accordMode === true) {
                            let secondToggleBtn =
                                document.createElement("button");
                            secondToggleBtn.classList = "toggleBtn close";
                            secondToggleBtn.innerHTML =
                                'toggleBtn <i class="ic-arrow-down"></i>';
                            secondToggleBtn.style.display = "none";
                            secondToggleBtn.addEventListener(
                                "click",
                                this.accordToggleText
                            );
                            condition.appendChild(secondToggleBtn);
                        }
                    }
                    condition = condition.nextElementSibling;
                }
                perrentParag.remove();
            }
        },
        slideDown(target, duration = 200) {
            target.style.removeProperty("display");
            let display = window.getComputedStyle(target).display;
            if (display === "none") display = "block";
            target.style.display = display;
            let height = target.offsetHeight;
            target.style.overflow = "hidden";
            target.style.height = 0;
            target.style.paddingTop = 0;
            target.style.paddingBottom = 0;
            target.style.marginTop = 0;
            target.style.marginBottom = 0;
            target.offsetHeight;
            target.style.boxSizing = "border-box";
            target.style.transitionProperty = "height, margin, padding";
            target.style.transitionDuration = duration + "ms";
            target.style.height = height + "px";
            target.style.removeProperty("padding-top");
            target.style.removeProperty("padding-bottom");
            target.style.removeProperty("margin-top");
            target.style.removeProperty("margin-bottom");
            window.setTimeout(() => {
                target.style.removeProperty("height");
                target.style.removeProperty("overflow");
                target.style.removeProperty("transition-duration");
                target.style.removeProperty("transition-property");
            }, duration);
        },
        slideUp(target, duration = 200) {
            target.style.transitionProperty = "height, margin, padding";
            target.style.transitionDuration = duration + "ms";
            target.style.boxSizing = "border-box";
            target.style.height = target.offsetHeight + "px";
            target.offsetHeight;
            target.style.overflow = "hidden";
            target.style.height = 0;
            target.style.paddingTop = 0;
            target.style.paddingBottom = 0;
            target.style.marginTop = 0;
            target.style.marginBottom = 0;
            window.setTimeout(() => {
                target.style.display = "none";
                target.style.removeProperty("height");
                target.style.removeProperty("padding-top");
                target.style.removeProperty("padding-bottom");
                target.style.removeProperty("margin-top");
                target.style.removeProperty("margin-bottom");
                target.style.removeProperty("overflow");
                target.style.removeProperty("transition-duration");
                target.style.removeProperty("transition-property");
                //alert("!");
            }, duration);
        },
    },
};
