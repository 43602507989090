import Axios from "axios";

export default ({
    state: {
        footerInfo: [],
        footerMenu: [],
    },
    getters: {
        globalSetting(state) {
            return state.footerInfo ? state.footerInfo : null
        },
        blogTitle(state){
            return state.footerInfo.blog_title ? state.footerInfo.blog_title : null
        },
        blogDesc(state){
            return state.footerInfo.blog_description ? state.footerInfo.blog_description : null
        },
        footerMenuList(state) {
            return state.footerMenu ? state.footerMenu : null
        }
    },
    mutations: {
        setFooterData(state, data) {
            state.footerInfo = data
        },
        setFooterMenu(state, menu) {
            state.footerMenu = menu
        }
    },
    actions: {
        fetchData({commit}, data) {
            commit('setFooterData', data)
        },
        async footerFetch({commit}, lang) {
            const res = await Axios.post('/api/settings/all', {
                lang
            })
            commit('setFooterData', res.data.data)
        },
        getFooterMenu({commit}, menu) {
            commit('setFooterMenu', menu)
        }
    }
});
