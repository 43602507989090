<template>
	<div id="app">
		<app-header></app-header>
		<router-view :key="pathKey" />
		<page-preloader v-show="isUiLocked"></page-preloader>
		<app-footer></app-footer>
		<cookie-model></cookie-model>
	</div>
</template>

<script>
import appHeader from "@/components/navigation/app-header.vue";
import appFooter from "@/components/common/app-footer.vue";
import { mapActions, mapGetters } from "vuex";
import PagePreloader from "./components/common/pagePreloader.vue";
import CookieModel from './components/common/cookie-model.vue';
export default {
	components: {
		appHeader,
		appFooter,
		PagePreloader,
		CookieModel
	},
	methods: {
		...mapActions(["setCurrentSize", "lockUi", "unlockUi", "footerFetch"])
	},
	computed: {
		...mapGetters(["isUiLocked", "globalSetting"]),
		pathKey() {
			if (
				this.$route.name == "CountryTours" ||
				this.$route.name == "ContryHotels"
			) {
				return this.$route.path;
			} else return this.$route.fullPath.split("#").splice(0, 1).join();
		}
	},
	mounted() {
		window.addEventListener("resize", () => {
			this.setCurrentSize(window);
		});
		this.setCurrentSize(window);

		const root = document.documentElement;
		root.style.setProperty("--windowHeight", window.innerHeight + "px");

		window.addEventListener("scroll", function () {
			root.style.setProperty("--windowHeight", window.innerHeight + "px");
		});

		window.addEventListener("resize", function () {
			root.style.setProperty("--windowHeight", window.innerHeight + "px");
		});
	},
	watch: {
		$route() {
			this.footerFetch(this.currentLang);
		}
	},
	async created() {
		this.lockUi();
		await this.footerFetch(this.currentLang);
		if (this.globalSetting) {
			localStorage.setItem("google_map_key", this.globalSetting.maps_api_key);
		}
		this.unlockUi();
	}
};
</script>

<style lang="sass">
@import './assets/sass/global'
</style>
