var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hotelItem"},[_c('router-link',{attrs:{"to":_vm.propsData.link
        ? _vm.propsData.link
        : {
            name: 'HotelInfo',
            params: {
              slug: _vm.propsData.slug,
              locale: _vm.$i18n.locale == 'en' ? null : _vm.$i18n.locale
            }
          }}},[_c('div',{staticClass:"imgWrp"},[_c('img',{attrs:{"src":_vm.path(_vm.propsData.image),"alt":""}})]),_c('div',{staticClass:"infoBlock"},[_c('div',{staticClass:"star"},[_vm._v(_vm._s(_vm.propsData.stars)),_c('i',{staticClass:"ic-star"})]),_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.propsData.name || _vm.propsData.title))])]),_c('div',{staticClass:"infoText"},[(_vm.propsData.recommend)?_c('p',{staticClass:"like"},[_c('i',{staticClass:"icon ic-like"}),_vm._v(_vm._s(_vm.$t("rec"))+" ")]):_vm._e(),_c('p',{staticClass:"description",class:{noIcon: !_vm.propsData.recommend}},[_vm._v(" "+_vm._s(_vm.description)+" ")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }